import { useMemo } from 'react'

export function formatComparePrice({
  amount,
  currencyCode,
  locale = 'en-US',
}: {
  amount: number
  currencyCode: string
  locale: string
}) {
  const formatCurrency = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
  })

  return formatCurrency.format(amount)
}

export function formatVariantComparePrice({
  amount,
  baseAmount,
  currencyCode,
  locale = 'en-US',
}: {
  baseAmount: number
  amount: number
  currencyCode: string
  locale: string
}) {
  const hasDiscount = baseAmount > amount
  const formatDiscount = new Intl.NumberFormat(locale, { style: 'percent' })
  const discount = hasDiscount
    ? formatDiscount.format((baseAmount - amount) / baseAmount)
    : null

  const compareprice = formatComparePrice({ amount, currencyCode, locale })
  const basePrice = hasDiscount
    ? formatComparePrice({ amount: baseAmount, currencyCode, locale })
    : null

  return { compareprice, basePrice, discount }
}

export default function useComparePrice(
  data?: {
    amount: number
    baseAmount?: number
    currencyCode: string
  } | null
) {
  const { amount, baseAmount, currencyCode } = data ?? {}
  // TODO: Figure out better locale handling
  //console.log('Bcp ' + baseAmount)
  const locale = 'en-US'
  const value = useMemo(() => {
    if (typeof amount !== 'number' || !currencyCode) return ''

    return baseAmount
      ? formatVariantComparePrice({ amount, baseAmount, currencyCode, locale })
      : formatComparePrice({ amount, currencyCode, locale })
  }, [amount, baseAmount, currencyCode])

  return typeof value === 'string' ? { compareprice: value } : value
}
