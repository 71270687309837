import { FC } from 'react'
import { Product, ProductKeys } from '@common/types/product'
import usePrice from '@common/product/use-price'
import useComparePrice from '@common/product/use-compareprice'
import { productSpecsMetafields } from '@framework/utils/common'
import { Link, Image } from '@components/ui'
/**
 * Not sure why, but after upgrading to webpack5 and upgrading Next to version 12,
 * it errors out when importing via aliases and destructuring, i.e:
 * import { ProductAddToCart, ProductViewMedia } from '@components/product'
 *
 * So, for now importing relatively
 */
import ProductAddToCart from '../ProductAddToCart'
import ProductViewMedia from '../ProductViewMedia'
import { getLowestPricedProductVariant } from '../helpers'
import s from './ProductCard.module.scss'
interface Props {
  product: Product
}
const placeholderImage = '/static_images/ts-pool-supply-square-pool.jpg'

const ProductCard: FC<Props> = ({ product }) => {
  const hasOptions = product.options.length
  const variant = hasOptions
    ? getLowestPricedProductVariant(product)
    : product.variants[0]
  const quantity = 1
  const { price } = usePrice({
    amount: variant.price!,
    baseAmount: variant.listPrice!,
    currencyCode: product.price.currencyCode,
  })
  //const unadvertisedPrice = true
  const unadvertisedPrice = product.specialPrice
  const isUnAdvertised = Boolean(unadvertisedPrice)

  const { compareprice } = useComparePrice({
    amount: variant?.listPrice!,
    baseAmount: variant?.listPrice!,
    currencyCode: product.price.currencyCode,
  })
  const priceText = hasOptions ? `AS LOW AS ${price}` : `${price} PER UNIT`

  var showBestPrice = false
  let specs = productSpecsMetafields.map((spec) => {
    if (spec.label === "Best Price Available") {
      if (product[spec.key as ProductKeys] === "Yes") showBestPrice = true
      return {}
    }
  })

  return (
    <div className={s.root}>
      <div className={s.link}>
      <Link href={`/products${product.path}`}>
        <>
          <div>
            <h3 className={s.name}>
              <span>{product.name}</span>
            </h3>
          </div>
          <div className={s['product-image-wrap']}>
            {product.media.length > 0 ? (
              product.media.map((media, index) => (
                <div className={s['image-wrap']} key={index}>
                  <ProductViewMedia media={media} />
                </div>
              ))
            ) : (
              <div className={s['image-wrap']}>
                <Image
                  alt={product.name}
                  src={placeholderImage}
                  layout="fill"
                  objectFit="scale-down"
                />
              </div>
            )}
          </div>
        </>
      </Link>
          <div className={s['cart-wrap']}>
            {(!product.notAvailableOnline ||
              product.notAvailableOnline?.showPrice) && (
              <span className={s.price}>
                {compareprice != '$0.00' && compareprice != '$NaN' && compareprice != price && (
                  <span>
                    <del>{compareprice}</del>
                  </span>
                )}
                {priceText}
              </span>
            )}
            <div className={s['add-to-cart-wrap']}>

              {showBestPrice && product.notAvailableOnline && (
                <span className={s['toolow']}>
                  Too low to advertise
                </span>
              )}
              {!hasOptions ? (
                <ProductAddToCart
                  variant={variant}
                  productId={product.id}
                  productName={product.name}
                  quantity={quantity}
                  notAvailableOnline={product.notAvailableOnline}
                  availableForSale={variant?.availableForSale}
                  unadvertisedPrice={isUnAdvertised || false}
                  discountOff={product.hasDiscountOff}
                  beatCompetitorsPrice={product.hasBeatCompetitorsPrice}
                  hasRequestInstall={product.hasRequestInstall}
                  fullPage={false}
                />
              ) : (
                <Link
                  href={`/products${product.path}`}
                  className={s['see-options']}
                >
                  SEE OPTIONS
                </Link>
              )}
            </div>
          </div>
          </div>
    </div>
  )
}

export default ProductCard
