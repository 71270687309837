import { FC } from 'react'
import cn from 'classnames'
import { useUI } from '@components/ui/context'
import useAddItem from '@framework/cart/use-add-item'
import { getCheckoutId } from '@framework/utils'
import { ExternalLink } from '@components/ui'
import {
  ProductVariant,
  ProductNotAvailableOnline,
  ProductNotAvailableOnlineContactType,
} from '@common/types/product'
import s from './ProductAddToCart.module.scss'
import { ValuesOfCorrectTypeRule } from 'graphql'

interface Props {
  variant: ProductVariant | undefined
  productId: string
  productName: string
  notAvailableOnline: ProductNotAvailableOnline | null
  availableForSale: boolean | undefined
  quantity: number
  unadvertisedPrice: boolean
  beatCompetitorsPrice: boolean
  hasRequestInstall: boolean
  discountOff: string
  fullPage: boolean
}

const ProductAddToCart: FC<Props> = ({
  variant,
  productId,
  productName,
  notAvailableOnline,
  availableForSale,
  quantity,
  unadvertisedPrice,
  beatCompetitorsPrice,
  hasRequestInstall,
  discountOff,
  fullPage
}) => {
  const { openSidebar } = useUI()
  const { openChecklist } = useUI()
  const { openDiscount } = useUI()
  const { openCompetitor } = useUI()
  const { openUnadvertised } = useUI()
  const addItem = useAddItem()
  const itemId = String(variant?.id)
  const checkoutId = getCheckoutId()

  const addToCart = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    try {
      // TODO: Look into a better way to add item, maybe see how cartitem is doing it?
      const item = {
        productId: String(productId),
        productName: String(productName),
        variantId: String(variant?.id),
        quantity: Number(quantity)
      }
      await addItem(item)
      openSidebar()
    } catch (error) {
      throw error
    }
  }

  const addToChecklist = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    try {
      console.log('addToChecklist');
      const newitem = String(productName)
      var itemlist = localStorage.getItem('checklist')
      if (itemlist != null && itemlist.length > 1) {
        if (itemlist.includes(newitem)) itemlist = itemlist
        else itemlist = itemlist + '~' + newitem
      } else itemlist = newitem
      localStorage.setItem('checklist', String(itemlist))
      openChecklist()
    } catch (error) {
      throw error
    }
  }

  const addToChecklistDiscount = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    try {
      console.log('addToChecklistDiscount');
      const newitem = String(productName)
      var itemlist = localStorage.getItem('checklistDiscount')
      if (itemlist != null && itemlist.length > 1) {
        if (itemlist.includes(newitem)) itemlist = itemlist
        else itemlist = itemlist + '~' + newitem
      } else itemlist = newitem
      localStorage.setItem('checklistDiscount', String(itemlist))
      openDiscount()
    } catch (error) {
      throw error
    }
  }

  const addToChecklistCompetitor = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    try {
      console.log('addToChecklistCompetitor');
      const newitem = String(productName)
      var itemlist = localStorage.getItem('checklistCompetitor')
      if (itemlist != null && itemlist.length > 1) {
        if (itemlist.includes(newitem)) itemlist = itemlist
        else itemlist = itemlist + '~' + newitem
      } else itemlist = newitem
      localStorage.setItem('checklistCompetitor', String(itemlist))
      openCompetitor()
    } catch (error) {
      throw error
    }
  }

  const addToChecklistUnadvertised = async (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    try {
      console.log('addToChecklistUnadvertised');
      const newitem = String(productName)
      var itemlist = localStorage.getItem('checklistUnadvertised')
      if (itemlist != null && itemlist.length > 1) {
        if (itemlist.includes(newitem)) itemlist = itemlist
        else itemlist = itemlist + '~' + newitem
      } else itemlist = newitem
      localStorage.setItem('checklistUnadvertised', String(itemlist))
      openUnadvertised()
    } catch (error) {
      throw error
    }
  }

  if (fullPage) {
    return (
      <>
        {notAvailableOnline ? (
          notAvailableOnline.contactType ===
          ProductNotAvailableOnlineContactType.Call ? (
            <ExternalLink
              href="tel:6025324863"
              className={cn(s['purchase-btn'], s['not-available-online'])}
            >
              {notAvailableOnline.reasonText}
            </ExternalLink>
          ) : (
            <button
              className={cn(s['purchase-btn'], s['add-to-cart'])}
              onClick={addToChecklist}
            >
              {notAvailableOnline.reasonText}
            </button>
          )
        ) : (
          <button
            className={cn(
              s['purchase-btn'],
              s['add-to-cart'],
              availableForSale ? '' : s['inactive-cart']
            )}
            onClick={availableForSale ? addToCart : () => {}}
          >
            {availableForSale ? 'ADD TO CART' : 'OUT OF STOCK'}
          </button>
        )}
        {discountOff ? (
          <button
          className={cn(
            s['special-btn'],
            s['add-to-cart'],
            fullPage ? s['white-btn'] : s['gray-btn']
          )}
          onClick={addToChecklistDiscount}>
            ${discountOff} OFF
          </button>
        ) : null}
        {unadvertisedPrice ? (
          <button
          className={cn(
            s['special-btn'],
            s['add-to-cart'],
            fullPage ? s['white-btn'] : s['gray-btn']
          )}
          onClick={addToChecklistUnadvertised}>
            Unadvertised Deal
          </button>
        ) : null}
        {beatCompetitorsPrice ? (
          <button
          className={cn(
            s['special-btn'],
            s['add-to-cart'],
            s['beat-competitor'],
            fullPage ? s['white-btn'] : s['gray-btn']
          )}
          onClick={addToChecklistCompetitor}>
            Beat Competitor's Price*
          </button>
        ) : null}
        {hasRequestInstall ? (
          <ExternalLink
            href="https://shop.tspoolsupply.com/pages/product-install"
            className={cn(
              s['special-btn'],
              s['add-to-cart'],
              fullPage ? s['white-btn'] : s['gray-btn']
            )}
          >
            Request Install<span>ation</span> Price
          </ExternalLink>
        ) : null}
        <div id="variant_id" className={s['variantId']}>{itemId}</div>
        <div id="checkout_id" className={s['checkoutId']}>{checkoutId}</div>
      </>
    )
  } else {
    return (
      <>
        {notAvailableOnline ? (
          notAvailableOnline.contactType ===
          ProductNotAvailableOnlineContactType.Call ? (
            <ExternalLink
              href="tel:6025324863"
              className={cn(s['purchase-btn'], s['not-available-online'])}
            >
              {notAvailableOnline.reasonText}
            </ExternalLink>
          ) : (
            <button
              className={cn(s['purchase-btn'], s['add-to-cart'])}
              onClick={addToChecklist}
            >
              {notAvailableOnline.reasonText}
            </button>
          )
        ) : (
          <button
            className={cn(
              s['purchase-btn'],
              s['add-to-cart'],
              availableForSale ? '' : s['inactive-cart']
            )}
            onClick={availableForSale ? addToCart : () => {}}
          >
            {availableForSale ? 'ADD TO CART' : 'OUT OF STOCK'}
          </button>
        )}
        {discountOff ? (
          <button
          className={cn(
            s['special-btn'],
            s['add-to-cart'],
            fullPage ? s['white-btn'] : s['gray-btn']
          )}
          onClick={addToChecklistDiscount}>
            ${discountOff} OFF
          </button>
        ) : unadvertisedPrice ? (
          <button
          className={cn(
            s['special-btn'],
            s['add-to-cart'],
            fullPage ? s['white-btn'] : s['gray-btn']
          )}
          onClick={addToChecklistUnadvertised}>
            Unadvertised Deal
          </button>
        ) : beatCompetitorsPrice ? (
          <button
          className={cn(
            s['special-btn'],
            s['add-to-cart'],
            s['beat-competitor'],
            fullPage ? s['white-btn'] : s['gray-btn']
          )}
          onClick={addToChecklistCompetitor}>
            Beat Competitor's Price*
          </button>
        ) : hasRequestInstall ? (
          <ExternalLink
            href="https://shop.tspoolsupply.com/pages/product-install"
            className={cn(
              s['special-btn'],
              s['add-to-cart'],
              fullPage ? s['white-btn'] : s['gray-btn']
            )}
          >
            Request Install<span>ation</span> Price
          </ExternalLink>
        ) : null}
        <div id="variant_id" className={s['variantId']}>{itemId}</div>
        <div id="checkout_id" className={s['checkoutId']}>{checkoutId}</div>
      </>
    )
  }
}

export default ProductAddToCart
