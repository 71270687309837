import { FC } from 'react'
import {
  ProductMedia,
  ProductMediaType,
  ProductImage,
  ProductVideo,
  ProductExternalVideo,
} from '@common/types/product'
import { Image } from '@components/ui'
import s from './ProductViewMedia.module.scss'

interface Props {
  media: ProductMedia
}

const ProductViewMedia: FC<Props> = ({ media }) => {
  switch (media.type) {
    case ProductMediaType.Image:
      const dataImage = media as ProductImage

      return (
        <Image
          alt={dataImage.alt}
          src={dataImage.url}
          layout="fill"
          objectFit="scale-down"
          /**
           * Currently, product images don't exceed 480px, but if they do,
           * we need to account for that here
           * */
          sizes="480px"
        />
      )
    case ProductMediaType.ExternalVideo:
      const dataExternalVideo = media as ProductExternalVideo

      return (
        <iframe
          src={dataExternalVideo.url}
          allowFullScreen={true}
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
        />
      )
    case ProductMediaType.Video:
      const dataVideo = media as ProductVideo

      return (
        <video controls>
          {dataVideo.sources.map((source, i) => (
            <source key={i} src={source.url} type={source.mimeType} />
          ))}
        </video>
      )
    default:
      return null
  }
}

export default ProductViewMedia
